<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Video Preview</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="justify-center d-flex">
                <video class="max-w-full" :src="item.video" id="video-preview" controls v-show="item.video != ''"/>
              </v-col>
              <v-col cols="6" class="">
                <v-btn @click="$router.push({name: 'VideoList' })" color="primary"> Back </v-btn>              
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn @click="dltItem" color="error"> Delete </v-btn>              
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'VideoView',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {}
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load(1)
  },
  methods: {
    load(page) {
      var url = 'user-videos/' + this.$route.params.id
      this.$api.get(url).then(response => {
        this.item = response.data
        this.item.video = process.env.VUE_APP_MEDIA_URL + this.item.video
      })
    },
    dltItem() {
      if (confirm('Are you sure?')) {
        this.$api.post('user-videos/' + this.item.id, { _method: 'DELETE' }).then(response => {
          this.$toast.info(response.message)
          this.$router.push({ name: 'VideoList' })
        })
      }
    },
  },
}
</script>
